import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { useApplyCartDiscount, useRemoveCartDiscount } from '@bloomreach/connector-components-react'
import { Card, Chip, Grid, TextField } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { string } from 'prop-types'
import {
    useEffect, useMemo, useRef, useState, useContext
} from 'react'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import BaseButton from '../../../_Elements/Inputs/Button/BaseButton'
import useCustomCart from '../../../_Hooks/useCustomCart/useCustomCart'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce' 
import { useRouter } from 'next/router'

const Discount = ({
    className, cartId, storeKey, readOnly
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const [applyCartDiscount, applicationResult, applicationLoading, applicationError] = useApplyCartDiscount({
        cartId,
        storeKey
    })
    const { pushAddDiscountCode, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)
    const [removeCartDiscount, removalResult, removalLoading, removalError] = useRemoveCartDiscount({ cartId })
    const { cart: { discounts } } = useCustomCart()
    const router = useRouter()

    // waiting for bloomreach to actually fix their removeFromCart response
    const prevIsNotBrokenRef = useRef()
    const notBrokenDiscounts = useMemo(() => {
        if (!prevIsNotBrokenRef.current) {
            prevIsNotBrokenRef.current = discounts
            return discounts
        }

        const fixedDiscounts = discounts.map((discount) => {
            const prevDiscount = prevIsNotBrokenRef.current.find((d) => d.id === discount.id)

            return {
                ...discount,
                code: discount.code || prevDiscount?.code
            }
        })
        prevIsNotBrokenRef.current = fixedDiscounts
        return fixedDiscounts
    }, [discounts])

    const [discountCode, setDiscountCode] = useState('')

    const handleDeleteAll = async () => {
        await removeCartDiscount({
            discountCodes:
                discounts
                    ?.filter((d) => d.type === 'Promotion')
                    .map((discount) => discount.id)
        })
        setDiscountCode(() => '')
    }

    // deactivated until bloomreach provides discount.names
    const handleDelete = (chipToDelete) => () => {
        // discounts.filter((chip) => chip.id !== chipToDelete.id)
        removeCartDiscount({ discountCodes: chipToDelete.id })
    }

    const handleOnSubmit = async () => {
        const resp = await applyCartDiscount({ discountCodes: [discountCode] })
        if(resp.success){
            const products = resp.cart?.entries?.map((entry) => {
                const { items, quantity = '' } = entry
                const item = items && items.length ? items[0] : {}
                const [id = '', variant = ''] = item.itemId?.code.split('-')

                return prepareProductForDataLayer(item, { id, variant, quantity })
            })
            pushAddDiscountCode(discountCode, products)
        }
        setDiscountCode(() => '')
    }

    const [responseMessage, setResponseMessage] = useState(null)
    useEffect(() => { if (removalError) setResponseMessage(() => ({ type: 'error', msg: removalError.message })) }, [removalError])
    useEffect(() => { if (applicationError) setResponseMessage(() => ({ type: 'error', msg: applicationError.message })) }, [applicationError])
    useEffect(() => { if (applicationResult) setResponseMessage(() => ({ type: 'success', msg: applicationResult.message })) }, [applicationResult])
    useEffect(() => { if (removalResult) setResponseMessage(() => ({ type: 'success', msg: removalResult.message })) }, [removalResult])

    return (
        <>
            {!readOnly && (
                <Card className={clsx(classes.root, className)} elevation={0}>
                    <Typography variant='body1' className={classes.title}>
                        {translations('checkout:discount.title')}
                    </Typography>
                    <div
                        className={router.query?.checkoutStep !== "2" ? classes.form : classes.reviewForm}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={router.query?.checkoutStep !== "2" ? 5 : 12}
                                md={router.query?.checkoutStep !== "2" ? 4 : 8}>
                                <TextField
                                    value={discountCode}
                                    onChange={(e) => {
                                        e.persist()
                                        setDiscountCode(() => e.target.value.toUpperCase())
                                    }}
                                    sx={{ ml: 1, flex: 1 }}
                                    label={translations('checkout:discount.input.label')}
                                    data-qa='input-cart-discountCode'
                                />
                            </Grid>
                            <Grid
                                item
                                xs={router.query?.checkoutStep !== "2" ? 7 : 12}
                                md={router.query?.checkoutStep !== "2" ? 8 : 4}>
                                <BaseButton
                                    className={classes.button}
                                    variant='contained'
                                    disableElevation
                                    loading={applicationLoading}
                                    onClick={handleOnSubmit}
                                    data-qa='button-cart-useCode'
                                >
                                    {translations('checkout:discount.button')}
                                </BaseButton>

                            </Grid>

                        </Grid>
                        
                    </div>
                    {discounts.length > 1 && (
                        <Typography
                            aria-label='delete'
                            onClick={handleDeleteAll}
                            className={classes.deleteText}
                        >
                            {translations('checkout:discount.delete')}
                        </Typography>
                    )}
                    { !!responseMessage && (
                        <FormHelperText
                            error={responseMessage.type === 'error'}
                        >
                            {responseMessage.msg}
                        </FormHelperText>
                    )}
                </Card>
            )}

            { notBrokenDiscounts?.filter((d) => d.type === 'Promotion')?.length > 0 && (
                <Card component='ul' className={clsx(classes.root, className)} elevation={0}>
                    {readOnly && (
                        <Typography variant='body1' className={clsx(classes.title)}>
                            { translations('checkout:discount.title') }
                        </Typography>
                    )}
                    <div className={clsx(classes.chipRoot, readOnly && classes.paddingTop)}>
                        { notBrokenDiscounts?.filter((d) => d.type === 'Promotion').map((data) => (
                            <li key={data.id}>
                                <Chip
                                    label={data.code}
                                    onDelete={readOnly ? null : handleDelete(data)}
                                    className={classes.chip}
                                    variant='outlined'
                                    color='primary'
                                    disabled={!!removalLoading}
                                />
                            </li>
                        ))}
                    </div>
                </Card>
            )}
        </>
    )
}

Discount.propTypes = {
    className: string,
    cartId: string.isRequired,
    storeKey: string

}

Discount.defaultProps = {
    className: undefined,
    storeKey: undefined
}

export default Discount
