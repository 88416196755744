import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Typography
} from '@material-ui/core'
import {
    useState, useContext, useEffect
} from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import BaseButton from '../../_Elements/Inputs/Button/BaseButton'
import useTranslation from '../../_Elements/ResourceBundles/useTranslation'
import useStyles from './style'
import { COOKIE_LOCALE, COOKIE_LOCATION, COOKIE_REGION, COOKIE_LANGUAGE } from '../../_Mappings/cookies'
import shippingRegions from '../../_Mappings/shippingRegions'
import regionLocales from '../../_Mappings/regionLocales'
import LoginContext from '../Account/Login/LoginContext'
import languages from '../../_Mappings/languages'
import { usePageCfg } from '../../_Elements/PageConfig/PageCfg'
import shippingCountries from '../../_Mappings/shippingCountries'
import { formatSimpleBbcode, getLoginStatus } from '../../../lib/utils'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import { GoogleEnhancedEcommerce } from '../ThirdPartyScripts/GoogleEnhancedEcommerce'

const maxAge = 60 * 60 * 24 * 30
const TOKEN = 'token'

const LanguageDialog = ({
    modalIsOpen, setModalIsOpen, language, languageLabel, setCookie, location,
}) => {
    const cfg = usePageCfg()
    const classes = useStyles()
    const translations = useTranslation()
    const [user] = useCurrentCustomer()
    const isLoggedIn = getLoginStatus(user) !== 'Guest'
    const { asPath } = useRouter()
    const [cookies] = useCookies([COOKIE_LOCATION, COOKIE_LANGUAGE])
    const [location_, setLocation_] = useState(location)
    const [language_, setLanguage_] = useState(language)
    const currentLocation = cookies[COOKIE_LOCATION]
    const currentLanguage = cookies[COOKIE_LANGUAGE]
    const { pushToDataLayer } = useContext(GoogleEnhancedEcommerce)

    useEffect(() => {
        setLocation_(location)
        setLanguage_(language)
     }, 
     [location, language] )

    const onSave = async () => {
        console.log(location_, currentLocation)
        if (location_ !== currentLocation) {
            // TODO: refactor with a new bloomreach implementation for getting a new cart depending on location
            // also see following component(s):  OrderSuccess
            // necessary workaround to receive a new token on location switch
            // the token contains currency and location, needed for a proper cart
            // this implementation logs the user out and forces a new token

            setCartId(undefined)
            sessionStorage.removeItem(TOKEN)

            if (location_ === undefined) {
                setLocation_('US')
            }
            setCookie(COOKIE_LOCATION, location_, {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
            setCookie(COOKIE_REGION, shippingRegions[location_], {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
            setCookie(COOKIE_LOCALE, regionLocales[location_], {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
        }

        if (language_ !== currentLanguage) {
            const pathname = asPath.split('?')[0]
            const languageMap = await cfg.httpClient.post(`${cfg.cmsRestserviceUrl}/multilanguage/pages`, {
                path: pathname,
                language: currentLanguage,
            })

            if (language_ === undefined){
                setLanguage_('en')
            }
            setCookie(COOKIE_LANGUAGE, language_, {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
            // resets the page with a hard refresh. part of the workaround from L52
            let href = Object.entries(languageMap.data).find(value => value[0] === language_)
            href = href !== undefined ? href[1] : ""
            window.location.href = (language_ !== 'en' ? `/${language_}${href}` : `${href}`) + window.location.search
        } else {
            window.location.reload();
        }
        
        setModalIsOpen(() => false)
        pushToDataLayer({ event: 'custom.historyChange' })
    }
    
    const onClose = () => {
        if(cookies[COOKIE_LOCATION] === undefined){
            setCookie(COOKIE_LOCATION, 'US', {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
            setCookie(COOKIE_REGION, shippingRegions['US'], {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
            setCookie(COOKIE_LOCALE, regionLocales['US'], {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
        }
        if(cookies[COOKIE_LANGUAGE] === undefined){
            setCookie(COOKIE_LANGUAGE, 'en', {
                maxAge, path: '/', sameSite: 'None', secure: true
            })
        }
        setModalIsOpen(() => false)
    }

     // Cart
     const { setCartId } = useContext(LoginContext)

    return (
        <Dialog
            onClose={onClose}
            open={modalIsOpen}
        >
            <DialogTitle disableTypography className={classes.titleWithClose}>
                <Typography variant='subtitle2'>{translations('shippinglocation.modal.title')}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translations('shippinglocation.modal.description', { language: languageLabel })}
                </DialogContentText>
                <Select
                    className={classes.select}
                    fullWidth
                    value={location_}
                    onChange={(e) => setLocation_(e.target.value)}
                    color='primary'
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {shippingCountries.map(({
                        value,
                        label
                    }) => (
                        <MenuItem
                            key={value}
                            value={value}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </Select>
                {(location_ !== currentLocation) && isLoggedIn &&
                <>
                    <Typography className={classes.labelwarn} color='error'>
                        {formatSimpleBbcode(translations('shippinglocation.modal.alert', { language: languageLabel }))}
                    </Typography>
                    <br/>
                </>}
                <DialogContentText>
                    {translations('shippinglocation.modal.description.language', { language: languageLabel })}
                </DialogContentText>
                <Select
                    className={classes.select}
                    fullWidth
                    value={language_}
                    onChange={(e) => setLanguage_(e.target.value)}
                    color='primary'
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {languages.map(({
                        value,
                        label
                    }) => (
                        <MenuItem
                            key={value}
                            value={value}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <BaseButton
                    onClick={onSave}
                    color='primary'
                >
                    {translations('button.close')}
                </BaseButton>
            </DialogActions>
        </Dialog>
    )
}

LanguageDialog.propTypes = {
    modalIsOpen: PropTypes.bool,
    setModalIsOpen: PropTypes.func,
    language: PropTypes.string,
    languageLabel: PropTypes.string,
    setCookie: PropTypes.func,
    location: PropTypes.string,
}

LanguageDialog.defaultProps = {
    modalIsOpen: undefined,
    setModalIsOpen: undefined,
    language: undefined,
    languageLabel: undefined,
    setCookie: undefined,
    location: undefined,
}

export default LanguageDialog
